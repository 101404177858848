"use client";
import type { Decision } from "api/ads/types";
import type { Price, Product } from "api/products/types";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { resolver as adsResolver } from "api/ads";
import { cn } from "../../utils";
import { gtmEvent } from "utils/gtm";
import { ProductCardNew } from "../molecules/product-card-new";

interface NativeAdProps {
  ad?: Decision | null;
  className?: string;
}

const NativeAd = memo(({ ad, className }: NativeAdProps) => {
  const { ref, inView } = useInView({ threshold: 0.5 });
  const [viewEventFired, setViewEventFired] = useState(false);
  const [impressionEventFired, setImpressionEventFired] = useState(false);
  const viewTimer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (!inView || !ad || viewEventFired)
      return clearTimeout(viewTimer.current);

    const viewEvent = ad.events.find(({ id }) => id === 40);

    if (viewEvent) {
      viewTimer.current = setTimeout(async () => {
        await adsResolver.sendImpression(viewEvent.url);

        gtmEvent(`kevel_native_ad_inview`, ad);
        setViewEventFired(true);
      }, 1000);
    }
  }, [inView]);

  useEffect(() => {
    if (!inView || !ad || impressionEventFired) return;

    void adsResolver.sendImpression(ad.impressionUrl);
    gtmEvent(`kevel_native_ad_delivered`, ad);

    setImpressionEventFired(true);
  }, [ad, inView]);

  const currentAd = useMemo(() => {
    if (!ad) return;

    const data = ad.contents[0].data;

    if (data.ctProduct) {
      const product = data.ctProduct as Product;
      const prices = data.ctPrices as Price[];
      let priceLowest = product.priceLowest;

      if (data.ctShopId) {
        const shopPrice = prices.find(
          (price) => price.shopId === Number(data.ctShopId)
        );

        priceLowest = shopPrice
          ? {
              shopId: shopPrice.shopId,
              shopName: shopPrice.shop.name,
              amount: shopPrice.amount,
              link: shopPrice.link,
              id: shopPrice.ean,
              cpcRate: Number(data.ctCpcValue || "1.5"),
            }
          : null;
      }

      if (!data.ctShopId && prices.length > 0)
        priceLowest = {
          shopId: prices[0].shopId,
          shopName: prices[0].shop.name,
          amount: prices[0].amount,
          link: prices[0].link,
          id: prices[0].ean,
          cpcRate: Number(data.ctCpcValue || "1.5"),
        };

      return (
        <ProductCardNew
          ref={ref}
          key={product.kisId}
          {...product}
          priceLowest={priceLowest}
          slug={ad.clickUrl}
          type="product"
          thumbnail={product.media[0]}
          className={cn("h-full w-full", className)}
          buttonText={data.ctClickoutLinkText as string | undefined}
          sponsored
        />
      );
    }

    const formattedPrice = ((data.ctPrice as string) || "0")
      .replaceAll("€", "")
      .replaceAll(".", "")
      .replaceAll(",", ".")
      .replaceAll(" ", "");

    return (
      <ProductCardNew
        ref={ref}
        title={data.ctTitle as string}
        slug={ad.clickUrl}
        type="product"
        thumbnail={data.ctDisplayImageUrl as string}
        linkTarget={data.ctTarget as string}
        titleText={data.ctText as string}
        priceAmount={data.ctPrice as number}
        className={cn("h-full w-full", className)}
        buttonText={data.ctClickoutLinkText as string | undefined}
        sponsored
        kieskeurigId={0}
        kisId={""}
        brand={""}
        priceLowest={{
          shopId: 0,
          shopName: "",
          id: "",
          link: ad.clickUrl,
          cpcRate: Number(data.ctCpcValue),
          amount: Number(formattedPrice),
        }}
        reviewCount={0}
        discount={0}
        aliases={[]}
        reviewScore={0}
        certificates={[]}
        family={null}
        familyId={null}
        seriesId={null}
        series={null}
        lowestPriceUntilNow={false}
        productCode={""}
        categorySlug={""}
      />
    );
  }, []);

  return currentAd;
});

NativeAd.displayName = "NativeAd";

export { NativeAd };
