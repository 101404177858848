"use client";
import { InView } from "react-intersection-observer";
import { resolver as productsResolver } from "api/products";
import InfiniteScroll from "react-infinite-scroll-component";
import { useEffect, useRef, useState } from "react";
import type { ProductListingEntry } from "api/products/types";
import type { Decision } from "api/ads/types";
import { useStore } from "@nanostores/react";
import { $filters, setProductsTotal } from "stores/filters";
import { ProductCardNew } from "../molecules/product-card-new";
import { ModalPrices } from "../molecules/modal-prices";
import { cn, getProductSlug } from "../../utils";
import { NativeAd } from "../atoms/native-ad";
import { AstroAd } from "../atoms/astro-ad";
import { Ad } from "../atoms/ad";
import { CardType, getPageCards } from "../../utils/listing";
import { PaginationBar } from "../molecules/pagination-bar";

interface CompareListingProductsProps {
  initialProducts: CardType[];
  initialFilters: Record<string, unknown>;
  initialPage?: number;
  initialTotalPages?: number;
  initialTotalProducts?: number;
  className?: string;
  hideAds?: boolean;
  adsConfig?: { appName: string; networkCode: string };
  showCategoryOnProductCards?: boolean;
  affiliateId?: string;
  forceCheapest?: boolean;
  displayProvider?: "google" | "kevel";
  forcePdp?: boolean;
}

const CompareListingProducts = ({
  initialProducts,
  initialFilters,
  className,
  adsConfig,
  initialPage = 1,
  initialTotalPages = 1,
  initialTotalProducts = 1,
  hideAds,
  showCategoryOnProductCards,
  affiliateId,
  forceCheapest,
  displayProvider = "google",
  forcePdp,
}: CompareListingProductsProps) => {
  const queryFilters = useStore($filters);
  const productsOffset = useRef(0);

  const [page, setPage] = useState(initialPage);
  const [totalPages, setTotalPages] = useState(initialTotalPages);
  const [totalProducts, setTotalProducts] = useState(initialTotalProducts);

  const [priceModalProduct, setPriceModalProduct] = useState<number | null>(
    null
  );

  const [cards, setCards] = useState<CardType[][]>([initialProducts]);

  const handlePageInView = (inView: boolean, pageNumber: number) => {
    if (!inView) return;

    const searchParams = new URLSearchParams(window.location.search);

    if (pageNumber === 1) {
      searchParams.delete("page");
    } else {
      searchParams.set("page", pageNumber.toString());
    }

    history.pushState(
      {},
      document.title,
      `${window.location.pathname}${searchParams.size > 0 ? `?${searchParams.toString()}` : ""}${window.location.hash}`
    );
  };

  const handlePriceModal = (kieskeurigId: number) => {
    setPriceModalProduct(kieskeurigId);
  };

  const handleFetch = async (pageToFetch?: number) => {
    if (affiliateId) productsResolver.setAffiliateId(affiliateId);

    const newPage = pageToFetch || page + 1;
    const offset = pageToFetch ? 0 : productsOffset.current;

    const data = await productsResolver.list({
      ...initialFilters,
      ...queryFilters,
      skip: 20 * (newPage - 1) - offset,
      limit: 20,
    });

    const pageProducts = await getPageCards(data.data, newPage, hideAds);
    productsOffset.current = offset + pageProducts.offset;

    if (pageToFetch) {
      setCards([pageProducts.cards]);
      setPage(pageToFetch);
      setTotalPages(Math.ceil(data.meta.total / 20));
      setTotalProducts(data.meta.total);
      setProductsTotal(data.meta.total);
    } else {
      setCards((prev) => [...prev, pageProducts.cards]);
      setPage(newPage);
    }
  };

  useEffect(() => {
    handleFetch(1);
  }, [JSON.stringify(queryFilters)]);

  return (
    <section id="listing" className={cn(className)}>
      {initialPage > 1 && (
        <PaginationBar currentPage={initialPage} totalPages={totalPages} />
      )}

      <InfiniteScroll
        dataLength={cards.flat().length}
        next={handleFetch}
        hasMore={cards.flat().length < totalProducts}
        scrollThreshold={0.75}
        style={{ overflow: "unset" }}
        loader={
          <div className="bg-primary text-primary-foreground p-2.5 w-full text-center col-span-full">
            Producten worden geladen...
          </div>
        }
      >
        {cards.map((cardsPage, pageIndex) => {
          return (
            <InView
              key={pageIndex}
              onChange={(inView) => handlePageInView(inView, pageIndex + 1)}
              className="relative flex flex-wrap items-stretch gap-2.5 mb-2.5 lg:mb-0 lg:gap-0 after:absolute after:bg-container-background after:content-['_'] after:h-full after:w-[1px] after:right-0 after:top-0"
            >
              {cardsPage.map((card, cardIndex) => {
                switch (card.type) {
                  case "native_ad": {
                    const ad = card as Decision;

                    return (
                      <NativeAd
                        key={`native_${cardIndex}_${ad.adId}`}
                        ad={ad}
                        className="lg:border-l-0 lg:border-b-0 lg:w-1/4 h-auto"
                      />
                    );
                  }
                  case "display_ad_leaderboard": {
                    if (displayProvider === "kevel")
                      return (
                        <>
                          <AstroAd
                            name="leaderboard"
                            className="w-full border-t pt-4 my-4"
                            height={200}
                            desktop
                          />

                          <AstroAd
                            name="leaderboardMobile"
                            className="w-full border-t pt-4"
                            height={280}
                            mobile
                          />
                        </>
                      );

                    return (
                      <>
                        <Ad
                          networkCode={adsConfig!.networkCode}
                          adUnit={adsConfig!.appName}
                          name="top-horizontal"
                          id={pageIndex.toString()}
                          sizes={[
                            [728, 90],
                            [728, 200],
                          ]}
                          targetingArguments={{ pos: "leaderboard_top" }}
                          height={90}
                          className="w-full border-t pt-4 my-4"
                          desktop
                        />

                        <Ad
                          networkCode={adsConfig!.networkCode}
                          adUnit={adsConfig!.appName}
                          name="top-square"
                          id={pageIndex.toString()}
                          sizes={[
                            [336, 280],
                            [300, 250],
                          ]}
                          targetingArguments={{ pos: "rectangle" }}
                          className="w-full border-t pt-4"
                          height={280}
                          mobile
                        />
                      </>
                    );
                  }
                  case "display_ad": {
                    if (displayProvider === "kevel")
                      return (
                        <AstroAd
                          name={`mid${cardIndex < 10 ? 1 : 2}`}
                          className="w-full lg:w-1/2 border-t my-0"
                          height={280}
                        />
                      );

                    return (
                      <Ad
                        networkCode={adsConfig!.networkCode}
                        adUnit={adsConfig!.appName}
                        name={`mid_${cardIndex < 10 ? 1 : 2}`}
                        id={pageIndex.toString()}
                        targetingArguments={{
                          pos:
                            cardIndex < 10
                              ? "rectangle_top"
                              : "rectangle_bottom",
                        }}
                        sizes={[
                          [336, 280],
                          [300, 250],
                        ]}
                        height={280}
                        className="w-full lg:w-1/2 border-t my-0"
                      />
                    );
                  }
                  default: {
                    const product = card as ProductListingEntry;

                    return (
                      <ProductCardNew
                        key={product.kieskeurigId}
                        {...product}
                        slug={getProductSlug(
                          product.categorySlug,
                          product.slug
                        )}
                        className="lg:border-l-0 lg:border-b-0 lg:w-1/4 h-auto"
                        showCategory={showCategoryOnProductCards}
                        onClickout={handlePriceModal}
                        forceCheapest={forceCheapest}
                        forcePdp={forcePdp}
                      />
                    );
                  }
                }
              })}
            </InView>
          );
        })}
      </InfiniteScroll>

      <ModalPrices
        productId={priceModalProduct}
        open={priceModalProduct !== null}
        onClose={() => setPriceModalProduct(null)}
      />
    </section>
  );
};

export { CompareListingProducts };
