import { Icon } from "ui/icon";
import { ComparePriceCard } from "ui/compare-price-card";
import { resolver as productsResolver } from "api/products";
import { useEffect, useState } from "react";
import { Price, Product } from "api/products/types";
import { buttonVariants } from "../atoms/button";
import { sanitizeCategorySlug } from "../../utils";

interface ModalPricesProps {
  open?: boolean;
  productId: number | null;
  onClose: () => void;
}

const ModalPrices = ({ productId, open, onClose }: ModalPricesProps) => {
  const [prices, setPrices] = useState<Price[]>([]);
  const [product, setProduct] = useState<Product | null>(null);

  useEffect(() => {
    const fetchPrices = async () => {
      if (!productId) return;

      const data = await productsResolver.getPrices(productId);
      setPrices(data.filter((price) => !price.shop.marketplace).splice(0, 3));
    };

    const fetchProduct = async () => {
      if (!productId) return;

      const data = await productsResolver.getById(productId);
      setProduct(data);
    };

    fetchPrices().catch(() => onClose());
    fetchProduct().catch(() => onClose());
  }, [productId]);

  if (!open || !product || prices.length === 0) return;

  return (
    <div className="fixed w-screen h-screen top-0 left-0 z-50">
      <div
        className="bg-black/40 relative w-full h-full cursor-pointer"
        onClick={onClose}
      />

      <div className="bg-card absolute top-2.5 left-1/2 -translate-x-1/2 rounded w-full max-w-screen-lg max-h-screen overflow-y-scroll py-4">
        <div className="flex justify-end border-b px-8 pb-4 items-center">
          <button type="button" onClick={onClose}>
            <Icon icon="close" size={16} />
          </button>
        </div>

        <div className="p-8 flex flex-col items-center">
          <h2 className="mb-2.5 w-full">Meer prijzen bekijken?</h2>
          <p>
            Het product waar je naar hebt gekeken wordt ook door de onderstaande
            winkels aangeboden. Klik op "Bekijk product" om het aanbod van de
            winkel te zien.
          </p>

          <div className="flex flex-col gap-2.5 my-6 w-full">
            {prices.map((price, index) => (
              <ComparePriceCard
                key={price.shopId}
                {...price}
                position={index + 1}
                averagePrice={product.averagePrice}
                brand={product.brand}
                title={product.title}
                kieskeurigId={product.kieskeurigId}
              />
            ))}
          </div>

          <a
            className={buttonVariants()}
            href={`/${sanitizeCategorySlug(product.categorySlug)}/product/${product.slug}#prijzen`}
          >
            Bekijk alle prijzen
          </a>
        </div>
      </div>
    </div>
  );
};

export { ModalPrices };
