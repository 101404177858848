import { Decision } from "api/ads/types";
import { ProductListingEntry } from "api/products/types";
import { resolver as adsResolver } from "api/ads";

type ProductCard = ProductListingEntry & { type: "product" };
type DisplayAdCard = { ads: Decision[] | null } & { type: "display_ad" };
type DisplayAdLeaderboardCard = { ads: Decision[] | null } & {
  type: "display_ad_leaderboard";
};
type NativeAdCard = Decision & { type: "native_ad" };

export type CardType =
  | ProductCard
  | DisplayAdCard
  | NativeAdCard
  | DisplayAdLeaderboardCard;

export const getPageCards = async (
  pageProducts: ProductListingEntry[],
  page: number,
  hideAds?: boolean,
  hideNatives?: boolean
): Promise<{ cards: CardType[]; offset: number }> => {
  let offset = 0;

  const productsWithType: CardType[] = pageProducts.map(
    (product) =>
      ({
        ...product,
        type: "product",
      }) as ProductCard
  );

  if (!hideAds) {
    if (!hideNatives && typeof window !== "undefined") {
      const ads = await adsResolver.get({
        placements: [
          {
            divName: "productTile",
            adTypes: [301, 157],
            count: 5,
          },
        ],
      });

      const nativeAds = ads.decisions.productTile || [];

      const filledPositions: number[] = [];
      const allowedPositions = [1, 4, 6, 11, 16];

      nativeAds.forEach((ad) => {
        const positions = allowedPositions.filter(
          (pos) => !filledPositions.includes(pos)
        );

        const position = positions[0];

        if (position) {
          filledPositions.push(positions[0]);

          productsWithType.splice(positions[0], 0, {
            ...ad,
            type: "native_ad",
          } as NativeAdCard);
        }
      });

      const nativeAdCards = productsWithType.filter(
        (product) => product.type === "native_ad"
      );

      if (nativeAdCards.length > 0) {
        const removedCards = productsWithType.splice(-nativeAdCards.length);
        offset = removedCards.length;
      }
    }

    productsWithType.splice(page === 1 ? 2 : 6, 0, {
      type: "display_ad",
    } as DisplayAdCard);

    productsWithType.splice(11, 0, {
      type: "display_ad_leaderboard",
    } as DisplayAdLeaderboardCard);

    productsWithType.splice(productsWithType.length, 0, {
      type: "display_ad",
    } as DisplayAdCard);
  }

  return { cards: productsWithType, offset };
};
